import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
// import PreviewCompatibleImage from './PreviewCompatibleImage'

const BlogRollTemplate = (props) => {
  const { edges: posts } = props.data.allMarkdownRemark

  return (
    <div className="blogroll">
      {posts &&
        posts.map(({ node: post }) => (
          <section
            key={post.id}
            className={`tile ${post.frontmatter.featuredpost ? 'is-featured' : ''}`}
          >
            <header>
              {/* {post?.frontmatter?.featuredimage && (
                <div className="featured-thumbnail">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                </div>
              ) } */}
              <p className="post-meta">
                <Link className="title" to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
                <span className="subtitle">{post.frontmatter.date}</span>
              </p>
            </header>
            <article>
              <p>{post.excerpt}</p>
            </article>
            <footer>
              <Link className="btn" to={post.fields.slug}>
                Keep Reading →
              </Link>
            </footer>
          </section>
        ))}
    </div>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  )
}
